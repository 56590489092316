img {
    width: 100%;
}

@media (min-width: 768px) {
    img {
        display: block;
        max-width: 768px;
        margin-left: auto;
        margin-right: auto;
    }
}

main {
    overflow-x: hidden;
    width: 100%;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
}

@media (min-width: 768px) {
    main {
        max-width: 768px;
    }
}

h2 {
    font-size: 0.75rem;
    line-height: 1rem;
    opacity: .75;
    text-transform: uppercase;
}

h1 {
    color: #33499D;
    font-size: 3rem;
    line-height: 1;
    opacity: .9;
    text-transform: uppercase;
}

p {
    font-size: 0.75rem;
    line-height: 1rem;
    opacity: .75;
}

div {
    margin: 1rem 0;
}